import styled from 'styled-components';
import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button';

interface IButtonExtraProps {
  padding?: number;
}

export const Basicbutton = styled(<any>Button)<ButtonProps & IButtonExtraProps>`
  min-height: 48px;
  border-radius: 4px;
  padding: 0 ${props => props.padding || 30}px;
  font-size: ${props => props.theme.sizes.M};
  font-weight: ${props => props.theme.fontWeights.semiBold};
`;

export const BlueButton = styled(Basicbutton as any)`
  background-color: ${props => props.theme.colors.blue};
  color: ${props => props.theme.colors.white};
  border: none;
  &:hover {
    background-color: ${props => props.theme.colors.darkBlue};
    color: ${props => props.theme.colors.white};
  }
  &:disabled {
    background-color: ${props => props.theme.colors.superLightBlue};
    color: ${props => props.theme.colors.white};
  }
  &:active,
  &:focus {
    background-color: ${props => props.theme.colors.vividBlueLight};
    color: ${props => props.theme.colors.white};
  }
`;

export const GreyButton = styled(Basicbutton)`
  background-color: ${props => props.theme.colors.darkGrey};
  color: ${props => props.theme.colors.white};
  &:hover {
    background-color: ${props => props.theme.colors.grey};
    color: ${props => props.theme.colors.white};
  }
  &:disabled {
    background-color: ${props => props.theme.colors.superlightGrey};
    color: ${props => props.theme.colors.white};
  }
  &:active,
  &:focus {
    background-color: ${props => props.theme.colors.superDarkGrey};
    color: ${props => props.theme.colors.white};
  }
`;

export const RedButton = styled(Basicbutton)`
  background-color: ${props => props.theme.colors.red};
  color: ${props => props.theme.colors.white};
  &:hover {
    background-color: ${props => props.theme.colors.darkRed};
    color: ${props => props.theme.colors.white};
  }
  &:disabled {
    background-color: ${props => props.theme.colors.lightRed};
    color: ${props => props.theme.colors.white};
  }
  &:active,
  &:focus {
    background-color: ${props => props.theme.colors.superDarkRed};
    color: ${props => props.theme.colors.white};
  }
`;

export const WhiteBlueButton = styled(Basicbutton)`
  background-color: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.blue};
  border: 1px solid ${props => props.theme.colors.blue};
  &:hover {
    background-color: ${props => props.theme.colors.lightGrey};
    color: ${props => props.theme.colors.blue};
    border: 1px solid ${props => props.theme.colors.blue};
  }
  &:disabled {
    background-color: ${props => props.theme.colors.white};
    color: ${props => props.theme.colors.superLightBlue};
    border: 1px solid ${props => props.theme.colors.superLightBlue};
  }
  &:active,
  &:focus {
    background-color: ${props => props.theme.colors.white};
    color: ${props => props.theme.colors.vividBlueLight};
    border: 1px solid ${props => props.theme.colors.vividBlueLight};
  }
`;

export const WhiteRedButton = styled(Basicbutton)`
  background-color: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.red};
  border: 1px solid ${props => props.theme.colors.white};
  &:hover {
    background-color: ${props => props.theme.colors.lightGrey};
    color: ${props => props.theme.colors.red};
    border: 1px solid ${props => props.theme.colors.white};
  }
  &:disabled {
    background-color: ${props => props.theme.colors.white};
    color: ${props => props.theme.colors.red};
    border: 1px solid ${props => props.theme.colors.white};
  }
  &:active,
  &:focus {
    background-color: ${props => props.theme.colors.white};
    color: ${props => props.theme.colors.superDarkRed};
    border: 1px solid ${props => props.theme.colors.white};
  }
`;

export const EmergencySelect = styled(Basicbutton)`
  min-height: 68px;
  padding: 0 35px;
  border: 1px solid ${props => props.theme.colors.vividBlueLight};
  border-radius: 3px;
  background-color: ${props => props.theme.colors.white};
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.33);
  color: ${props => props.theme.colors.vividBlueLight};
  font-size: ${props => props.theme.sizes.L};
  &:hover {
    background-color: ${props => props.theme.colors.lightGrey};
  }
  &:disabled {
    background-color: ${props => props.theme.colors.superlightGrey};
  }
  &:active,
  &:focus {
    background-color: ${props => props.theme.colors.blue};
    color: ${props => props.theme.colors.vividBlueLight};
  }
`;
export const ResetButton = styled(Basicbutton)`
  background-color: ${props => props.theme.colors.superLightBlue};
  color: ${props => props.theme.colors.blue};
  border: 1px solid ${props => props.theme.colors.superLightBlue};
  &:hover,
  &:active,
  &:focus {
    background-color: ${props => props.theme.colors.superLightBlue};
    color: ${props => props.theme.colors.blue};
    border: 1px solid ${props => props.theme.colors.superLightBlue};
  }
  &:disabled {
    background-color: ${props => props.theme.colors.superLightBlue};
    color: ${props => props.theme.colors.lightBlue};
    border: 1px solid ${props => props.theme.colors.superLightBlue};
  }
`;

export const ResetBlueWhiteButton = styled(WhiteBlueButton)`
  border: 1px solid ${props => props.theme.colors.white};
  &:hover, &:focus, &:active, &: disabled {
    background-color: ${props => props.theme.colors.white};
    border: 1px solid ${props => props.theme.colors.white};
  }
`;
export const Preview = styled(Basicbutton)`
  border: 1px solid ${props => props.theme.colors.moreSuperDarkerGrey};
  padding: 0 ${props => props.padding || 8}px;
  font-weight: ${props => props.theme.fontWeights.regular};
  font-size: ${props => props.theme.sizes.S};
  line-height: 22px;
  min-height: 24px;
  max-height: 24px;
  margin-right: 8px;
`;

export const DeleteButton = styled(Basicbutton)`
  border: 1px solid ${props => props.theme.colors.moreSuperDarkerGrey};
  padding: 8px ${props => props.padding || 8}px;
  font-weight: ${props => props.theme.fontWeights.regular};
  min-height: 40px;
  font-size: ${props => props.theme.sizes.L};
  color: ${props => props.theme.colors.deleteRed};
  background-color: rgba(0, 0, 0, 0.04);
  &:disabled {
    color: color: rgba(0,0,0,0.25);;
  }
  &:hover, &:focus, &:active {
    background-color: ${props => props.theme.colors.white};
    color: ${props => props.theme.colors.deleteRed};
    border-color: ${props => props.theme.colors.deleteRed};
  }
`;

export const SaveButton = styled(Basicbutton)`
  border: 1px solid ${props => props.theme.colors.moreSuperDarkerGrey};
  padding: 8px ${props => props.padding || 8}px;
  font-weight: ${props => props.theme.fontWeights.regular};
  min-height: 40px;
  font-size: ${props => props.theme.sizes.L};
  color:color: rgba(0,0,0,0.65);
  &:disabled {
    color: ${props => props.theme.colors.disabledGrey};
    background-color: rgba(0, 0, 0, 0.04);
    border: 1px solid ${props => props.theme.colors.moreSuperDarkerGrey};
  }

`;
export const PublishButton = styled(BlueButton)`
  border: none;
  margin-left: 8px;
  padding: 8px ${props => props.padding || 8}px;
  font-weight: ${props => props.theme.fontWeights.regular};
  min-height: 40px;
  font-size: ${props => props.theme.sizes.L};
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.blue};
  &:disabled {
    color: ${props => props.theme.colors.disabledGrey};
    background-color: rgba(0, 0, 0, 0.04);
    border: 1px solid ${props => props.theme.colors.moreSuperDarkerGrey};
  }
  &:hover,
  &:focus,
  &:active {
    background-color: ${props => props.theme.colors.vividBlueLight};
    color: ${props => props.theme.colors.white};
  }
`;
