import { push } from 'connected-react-router';
import { Dispatch } from 'redux';
import {
  ICreativity,
  IVisibility,
  CreativityFetchRequestAction,
  CreativityFetchSuccessAction,
  CreativityFetchFailureAction,
  CreativityFetchAllRequestAction,
  CreativityFetchAllSuccessAction,
  CreativityFetchAllFailureAction,
  CreativityAddRequestAction,
  CreativityAddSuccessAction,
  CreativityAddFailureAction,
  CreativityUpdateRequestAction,
  CreativityUpdateSuccessAction,
  CreativityUpdateFailureAction,
  CreativityUpdateElementProp,
  CreativityChangeSelection,
  CreativityChangeSortValue,
  CreativityUpdateTitle,
  CreativityUpdateVisibility,
  VisibilityRuleType,
  VisibilityRuleComparator,
  CreativityUpdatePlayers,
  CreativityUpdateGroups,
  CreativityUpdateProp,
  UpdateVisibilityActionCreator,
  UpdateCreativityActionCreator,
  AddCreativityFromTemplateActionCreator,
  FetchCreativityActionCreator,
  FetchCreativitiesActionCreator,
  EmergencyAddRequestAction,
  EmergencyAddSuccessAction,
  EmergencyAddFailureAction,
  AddEmergencyFromTemplateActionCreator,
  ActionResult,
  CreativityDeleteRequestAction,
  CreativityDeleteSuccessAction,
  CreativityDeleteFailureAction,
  resetCreativitiesErrorActionCreator,
  CreativitiesResetError,
  setCreativeDefaultRequestAction,
  setCreativeDefaultSuccessAction,
  setCreativeDefaultFailureAction,
  changeCreativeStatusRequestAction,
  changeCreativeStatusSuccessAction,
  changeCreativeStatusFailureAction,
} from './types';
import { selectTemplateById } from '../templates';
import { IPlayer } from '../players';
import { StatusNum } from '../creativities';
import {
  request,
  regenerateThumbnail,
  checkStatus,
  toJSON,
  stringToISOStringDate,
} from '../../../utils';
import { selectCreativitiesById } from './selector';
import { validate } from './validation';
import { Status } from '../creativities';

const startCreativityRequest = (): CreativityFetchRequestAction => ({
  type: 'CREATIVITIES/FETCH_REQUEST',
});

const fetchCreativitySuccess = (
  data: ICreativity
): CreativityFetchSuccessAction => ({
  type: 'CREATIVITIES/FETCH_SUCCESS',
  data,
});

const fetchCreativityFailure = (error: any): CreativityFetchFailureAction => ({
  type: 'CREATIVITIES/FETCH_FAILURE',
  error,
});

const startCreativitiesRequest = (): CreativityFetchAllRequestAction => ({
  type: 'CREATIVITIES/FETCH_ALL_REQUEST',
});

const fetchCreativitiesSuccess = (
  data: ICreativity[]
): CreativityFetchAllSuccessAction => ({
  type: 'CREATIVITIES/FETCH_ALL_SUCCESS',
  data,
});

const fetchCreativitiesFailure = (
  error: any
): CreativityFetchAllFailureAction => ({
  type: 'CREATIVITIES/FETCH_ALL_FAILURE',
  error,
});

const resetCreativitiesError = (): CreativitiesResetError => ({
  type: 'CREATIVITIES/RESET_ERROR',
});

const addCreativityRequest = (): CreativityAddRequestAction => ({
  type: 'CREATIVITIES/ADD_REQUEST',
});

const addCreativitySuccess = (
  data: ICreativity
): CreativityAddSuccessAction => ({
  type: 'CREATIVITIES/ADD_SUCCESS',
  data,
});

const addCreativityFailure = (error: any): CreativityAddFailureAction => ({
  type: 'CREATIVITIES/ADD_FAILURE',
  error,
});

const updateCreativityRequest = (): CreativityUpdateRequestAction => ({
  type: 'CREATIVITIES/UPDATE_REQUEST',
});

const updateCreativitySuccess = (
  data: ICreativity
): CreativityUpdateSuccessAction => ({
  type: 'CREATIVITIES/UPDATE_SUCCESS',
  data,
});

const updateCreativityFailure = (
  error: any
): CreativityUpdateFailureAction => ({
  type: 'CREATIVITIES/UPDATE_FAILURE',
  error,
});

const deleteCreativityRequest = (): CreativityDeleteRequestAction => ({
  type: 'CREATIVITIES/DELETE_REQUEST',
});

const deleteCreativitySuccess = (
  id: string
): CreativityDeleteSuccessAction => ({
  type: 'CREATIVITIES/DELETE_SUCCESS',
  id,
});

const deleteCreativityFailure = (
  error: any
): CreativityDeleteFailureAction => ({
  type: 'CREATIVITIES/DELETE_FAILURE',
  error,
});

export const updateElementProp = (
  id: string,
  prop: string,
  value: string
): CreativityUpdateElementProp => ({
  type: 'CREATIVITIES/UPDATE_ELEMENT_PROP',
  id,
  prop,
  value,
});

export const changeSelection = (
  section: string,
  value: number
): CreativityChangeSelection => ({
  type: 'CREATIVITIES/CHANGE_SELECTION',
  section,
  value,
});

export const updateTitle = (
  id: string,
  section: string,
  name: string
): CreativityUpdateTitle => ({
  type: 'CREATIVITIES/UPDATE_TITLE',
  id,
  section,
  name,
});

export const resetError: resetCreativitiesErrorActionCreator = () => {
  return (dispatch: Dispatch) => {
    dispatch(resetCreativitiesError());
  };
};

export const changeSortValue = (value: number): CreativityChangeSortValue => ({
  type: 'CREATIVITIES/CHANGE_SORT_VALUE',
  value,
});

export const fetchCreativity: FetchCreativityActionCreator = (id: string) => {
  return (dispatch: Dispatch) => {
    dispatch(startCreativityRequest());
    request(`creativities/${id}`)
      .then(checkStatus)
      .then(toJSON)
      .then(json => dispatch(fetchCreativitySuccess(json)))
      .catch(error => dispatch(fetchCreativityFailure(error)));
  };
};

export const fetchCreativities: FetchCreativitiesActionCreator = () => {
  return function(dispatch: Dispatch) {
    dispatch(startCreativitiesRequest());
    const typeRequest = `creativities/`;
    request(typeRequest)
      .then(checkStatus)
      .then(toJSON)
      .then(creativities => {
        request('emergencies')
          .then(checkStatus)
          .then(toJSON)
          .then(emergencies => {
            dispatch(
              fetchCreativitiesSuccess([...creativities, ...emergencies])
            );
          })
          .catch(error => dispatch(fetchCreativitiesFailure(error)));
      })
      .catch(error => dispatch(fetchCreativitiesFailure(error)));
  };
};

export const addCreativityFromTemplate: AddCreativityFromTemplateActionCreator = (data: {
  _id: string;
  name: string;
}) => async (dispatch: Dispatch, getState: any) => {
  const template = selectTemplateById(data._id)(getState());

  const body = {
    ...(template.emergency
      ? {
          emergency: template._id,
        }
      : {
          template: template._id,
        }),
    name: template.name,
    campaign: template.campaign,
    format: template.format,
  };

  const options = {
    method: 'POST',
    body: JSON.stringify(body),
  };

  dispatch(addCreativityRequest());
  return request('creativities', options)
    .then(checkStatus)
    .then(toJSON)
    .then(json => {
      dispatch(addCreativitySuccess(json));
      dispatch(push('/editor/' + json._id));
    })
    .catch(error => {
      dispatch(addCreativityFailure(error));
    });
};

export const updateCreativity: UpdateCreativityActionCreator = (
  id: string,
  status: 1 | 2 | 3 | 4 | 5 | undefined
) => {
  return (dispatch: Dispatch, getState: any) => {
    const creativity = selectCreativitiesById(getState(), id);

    if (!creativity) {
      return;
    }

    const players =
      creativity.players &&
      creativity.players.map(player => player._id || player);

    const url = `creativities/${creativity._id}`;

    const options = {
      method: 'PUT',
      body: JSON.stringify({
        ...creativity,
        ...(status && { status }),
        players,
      }),
    };

    dispatch(updateCreativityRequest());

    const errors = validate(creativity);
    if (errors && errors.length) {
      return dispatch(updateCreativityFailure(errors));
    }

    request(url, options)
      .then(checkStatus)
      .then(toJSON)
      .then(json => dispatch(updateCreativitySuccess(json)))
      .then(() => regenerateThumbnail(id))
      .catch(error => dispatch(updateCreativityFailure(error)));
  };
};

export const updateVisibilityAction = (
  id: string,
  data: IVisibility
): CreativityUpdateVisibility => ({
  id,
  type: 'CREATIVITIES/UPDATE_VISIBILITY',
  data,
});

export const updateVisibilityWeekdays: any = (
  id: string,
  weekDays: number[]
) => {
  return (dispatch: Dispatch, getState: any) => {
    const creativity = selectCreativitiesById(getState(), id);

    if (!creativity) {
      return;
    }

    const { visibility } = creativity;

    const rules =
      visibility && visibility.rules && visibility.rules.length
        ? visibility.rules.filter(rule => rule.type !== 'weekDay')
        : [];

    const weekDayRules = {
      type: 'weekDay',
      comparator: VisibilityRuleComparator.IN,
      value: weekDays,
    };

    dispatch(
      updateVisibilityAction(
        id,
        formatVisibility({
          ...creativity.visibility,
          rules: [...rules, weekDayRules],
        })
      )
    );
  };
};

export const updateVisibilityDateOrTime: UpdateVisibilityActionCreator = (
  id: string,
  type: VisibilityRuleType,
  comparator: VisibilityRuleComparator,
  value: string
) => {
  return (dispatch: Dispatch, getState: any) => {
    const creativity = selectCreativitiesById(getState(), id);

    if (!creativity) {
      return;
    }

    const { visibility } = creativity;

    const rules =
      visibility && visibility.rules && visibility.rules.length
        ? visibility.rules.filter(
            rule => !(rule.type === type && rule.comparator === comparator)
          )
        : [];

    const formattedVisibility = formatVisibility({
      ...visibility,
      rules: value
        ? [
            ...rules,
            {
              type,
              comparator,
              value: type === 'date' ? stringToISOStringDate(value) : value,
            },
          ]
        : rules,
    });

    dispatch(updateVisibilityAction(id, formattedVisibility));
  };
};

const formatVisibility = (visibility: IVisibility) => {
  if (!visibility || !visibility.rules || !visibility.rules.length) {
    return { ...visibility, state: 'visible' };
  }

  const Rules = visibility.rules
    .filter(
      rule =>
        rule.type === 'date' || rule.type === 'time' || rule.type === 'weekDay'
    )
    .map(
      (rule, index) =>
        index
          ? { ...rule, logic: 'and' }
          : { type: rule.type, comparator: rule.comparator, value: rule.value }
    );

  return {
    ...visibility,
    state: 'conditional',
    rules: [...Rules],
  };
};

export const unpublishCreativityByWarning = (
  creativity: ICreativity
): ActionResult<void> => {
  return updateCreativity(creativity._id, 2);
};

export const updateCreativityProp = (
  id: string,
  update: Partial<ICreativity>
): CreativityUpdateProp => ({
  type: 'CREATIVITIES/UPDATE_PROP',
  id,
  update,
});

export const updatePlayers = (
  id: string,
  players: IPlayer[]
): CreativityUpdatePlayers => ({
  type: 'CREATIVITIES/UPDATE_PLAYERS',
  id,
  players,
});

export const updateGroups = (
  id: string,
  groups: string[]
): CreativityUpdateGroups => ({
  type: 'CREATIVITIES/UPDATE_GROUPS',
  id,
  groups,
});

export const addEmergencyFromTemplate: AddEmergencyFromTemplateActionCreator = (data: {
  _id: string;
  name: string;
}) => async (dispatch: Dispatch, getState: any) => {
  const template = selectTemplateById(data._id)(getState());

  const body = {
    ...(template.emergency
      ? {
          emergency: template._id,
        }
      : {
          template: template._id,
        }),
    name: data.name,
    ...(template && {
      campaign: template.campaign && template.campaign._id,
    }),
    ...(template && {
      format: template.format && template.format._id,
    }),
  };

  const options = {
    method: 'POST',
    body: JSON.stringify(body),
  };

  const addEmergencyRequest = (): EmergencyAddRequestAction => ({
    type: 'EMERGENCIES/ADD_REQUEST',
  });

  const addEmergencySuccess = (
    data: ICreativity
  ): EmergencyAddSuccessAction => ({
    type: 'EMERGENCIES/ADD_SUCCESS',
    data,
  });

  const addEmergencyFailure = (error: any): EmergencyAddFailureAction => ({
    type: 'EMERGENCIES/ADD_FAILURE',
    error,
  });

  dispatch(addEmergencyRequest());
  return request('creativities', options)
    .then(checkStatus)
    .then(toJSON)
    .then(json => {
      dispatch(addEmergencySuccess(json));
      dispatch(push(`/editor/${json._id}/emergency`));
    })
    .catch(error => {
      dispatch(addEmergencyFailure(error));
    });
};

const startJobsRequest = () => ({
  type: 'CREATIVITIES/FETCH_JOBS_REQUEST',
});

const fetchJobsSuccess = (data: any) => ({
  type: 'CREATIVITIES/FETCH_JOBS_SUCCESS',
  data,
});

const fetchJobsFailure = (error: any) => ({
  type: 'CREATIVITIES/FETCH_JOBS_FAILURE',
  error,
});

export const fetchJobs = (id: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(startJobsRequest());
    try {
      const response = await request(`creativities/${id}/jobs`);
      await checkStatus(response);
      const json = await toJSON(response);
      dispatch(fetchJobsSuccess({ jobs: json, id }));
    } catch (error) {
      dispatch(fetchJobsFailure(error));
    }
  };
};

export const deleteCreativity = (id: string) => {
  return (dispatch: Dispatch) => {
    const options = {
      method: 'DELETE',
    };
    dispatch(deleteCreativityRequest());
    request(`creativities/${id}`, options)
      .then(checkStatus)
      .then(() => {
        dispatch(deleteCreativitySuccess(id));
        dispatch(push(`/creative`));
      })
      .catch(error => {
        dispatch(deleteCreativityFailure(error));
      });
  };
};

const setCreativeDefaultRequest = (): setCreativeDefaultRequestAction => ({
  type: 'CREATIVE/SET_DEFAULT_REQUEST',
});

const setCreativeDefaultSuccess = (
  data: any
): setCreativeDefaultSuccessAction => ({
  type: 'CREATIVE/SET_DEFAULT_SUCCESS',
  data,
});

const setCreativeDefaultFailure = (
  error: any
): setCreativeDefaultFailureAction => ({
  type: 'CREATIVE/SET_DEFAULT_FAILURE',
  error,
});

export const selectCreativeAsDefault = (creativeId: string) => {
  return (dispatch: Dispatch) => {
    dispatch(setCreativeDefaultRequest());

    const options = {
      method: 'PUT',
    };
    return request(`creativities/${creativeId}/default`, options)
      .then(() => {
        dispatch(setCreativeDefaultSuccess({ id: creativeId }));
      })
      .catch(error => {
        dispatch(setCreativeDefaultFailure(error));
      });
  };
};

const changeCreativeStatusRequest = (): changeCreativeStatusRequestAction => ({
  type: 'CREATIVE/CHANGE_STATUS_REQUEST',
});

const changeCreativeStatusSuccess = (
  status: Status,
  creativeId: string
): changeCreativeStatusSuccessAction => ({
  type: 'CREATIVE/CHANGE_STATUS_SUCCESS',
  status,
  creativeId,
});

const changeCreativeStatusFailure = (
  error: any
): changeCreativeStatusFailureAction => ({
  type: 'CREATIVE/CHANGE_STATUS_FAILURE',
  error,
});

export const changeCreativeStatus = (creativeId: string, status: Status) => {
  return (dispatch: Dispatch, getState: any) => {
    const creative = selectCreativitiesById(getState(), creativeId);

    const options = {
      method: 'PUT',
      body: JSON.stringify({
        ...creative,
        status,
      }),
    };

    dispatch(changeCreativeStatusRequest());

    return request(`creativities/${creativeId}`, options)
      .then(() => {
        dispatch(changeCreativeStatusSuccess(status, creativeId));
      })
      .catch(error => {
        dispatch(changeCreativeStatusFailure(error));
      });
  };
};
