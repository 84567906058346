import React, { Fragment } from 'react';
import { injectIntl, InjectedIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import {
  AppState,
  hasAllPlayersSelected,
  selectNumberOfSelected,
  updateCreativityProp,
  Status,
} from '../../../../redux/modules';
import { Radio } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import { ScreenSelection } from '../../../../components';
import {
  Section,
  SectionLine,
  CustomContainerButton,
  CustomButton,
  SectionTitle,
  SectionSubtitle,
  RadioButtonsContainer,
} from '../styles';

interface IProps {
  hasAllPlayersSelected?: boolean;
  id: string;
  intl?: InjectedIntl;
  numberOfSelected?: any;
  updateCreativityProp?: any;
  status: Status;
}

const SOME_SELECTED = 2;
const ALL_SELECTED = 1;

interface IState {
  activeModal?: boolean;
  selection: number;
}

class LocationSelector extends React.Component<IProps, IState> {
  state: IState = {
    selection: ALL_SELECTED,
  };

  componentDidMount() {
    this.setState({
      selection: this.props.hasAllPlayersSelected
        ? ALL_SELECTED
        : SOME_SELECTED,
    });
  }

  handleSelectAll = (e: RadioChangeEvent) => {
    const selection = e.target.value;

    this.props.updateCreativityProp(this.props.id, {
      allPlayers: selection === ALL_SELECTED,
    });
    this.setState({
      selection,
    });
  };

  handleCustomCancelModal = () => {
    this.setState({ activeModal: false });
  };

  handleCustomDone = () => {
    this.setState({ activeModal: false });
  };

  onSelectionChange = (e: RadioChangeEvent) =>
    this.props.updateCreativityProp(this.props.id, {
      allPlayers: e.target.value === ALL_SELECTED,
    });

  render() {
    const {
      id,
      intl,
      hasAllPlayersSelected,
      numberOfSelected,
      status,
    } = this.props;

    const radioStyle = {
      height: '30px',
      lineHeight: '22px',
      fontSize: '14px',
    };

    return (
      <Fragment>
        <Section>
          <SectionLine>
            <SectionTitle>
              <FormattedMessage id="editor.info.locations.title" />
            </SectionTitle>
            <SectionSubtitle>
              <FormattedMessage
                id="editor.info.locations.playersScreenTitle"
                values={{
                  number: hasAllPlayersSelected
                    ? this.props.intl!.formatMessage({ id: 'All' })
                    : numberOfSelected.players
                      ? numberOfSelected.players!.toString()
                      : '0',
                }}
              />
              <br />
              {numberOfSelected.groups > 0 &&
                !hasAllPlayersSelected && (
                  <FormattedMessage
                    id="editor.info.locations.groupsScreenTitle"
                    values={{
                      number: numberOfSelected.groups
                        ? numberOfSelected.groups!.toString()
                        : '0',
                    }}
                  />
                )}
            </SectionSubtitle>
          </SectionLine>

          <SectionLine>
            <RadioButtonsContainer
              value={
                this.props.hasAllPlayersSelected ? ALL_SELECTED : SOME_SELECTED
              }
            >
              <Radio
                disabled={
                  status === Status.PUBLISHED ||
                  status === Status.PENDING_VALIDATION
                }
                value={ALL_SELECTED}
                style={radioStyle}
                onChange={this.onSelectionChange}
              >
                <FormattedMessage id="editor.info.locations.allLocationsTitle" />
              </Radio>
              <Radio
                disabled={
                  status === Status.PUBLISHED ||
                  status === Status.PENDING_VALIDATION
                }
                value={SOME_SELECTED}
                style={radioStyle}
                onChange={this.onSelectionChange}
              >
                <FormattedMessage id="editor.info.locations.specificLocationsTitle" />
              </Radio>
            </RadioButtonsContainer>
            <CustomContainerButton>
              <CustomButton
                disabled={
                  this.props.hasAllPlayersSelected ||
                  status === Status.PUBLISHED ||
                  status === Status.PENDING_VALIDATION
                }
                onClick={() => {
                  this.setState({ activeModal: true });
                }}
                button={intl!.formatMessage({
                  id: 'editor.info.screen.buttonSelectScreen',
                })}
              />
            </CustomContainerButton>
          </SectionLine>
        </Section>
        <ScreenSelection
          id={id}
          visible={!!this.state.activeModal}
          handleCancel={this.handleCustomCancelModal}
          handleDone={this.handleCustomDone}
        />
      </Fragment>
    );
  }
}

export default connect(
  (state: AppState, props: IProps) => ({
    hasAllPlayersSelected: hasAllPlayersSelected(state, props.id),
    numberOfSelected: selectNumberOfSelected(state, props.id),
  }),
  {
    updateCreativityProp,
  }
)(injectIntl<any>(LocationSelector));
